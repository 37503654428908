// src/components/FloatingWhatsAppButton.js

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const FloatingWhatsAppButton = ({ phoneNumber, message }) => {
  const encodedMessage = encodeURIComponent(message);

  const handleClick = () => {
    window.open(
      `https://wa.me/${phoneNumber}?text=${encodedMessage}`,
      "_blank"
    );
  };

  return (
    <div style={containerStyle} onClick={handleClick}>
      <FontAwesomeIcon icon={faWhatsapp} size="2x" style={iconStyle} />
    </div>
  );
};

const containerStyle = {
  position: "fixed",
  bottom: "20px",
  right: "20px",
  backgroundColor: "#25D366",
  borderRadius: "50%",
  width: "60px",
  height: "60px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
};

const iconStyle = {
  color: "white",
};

export default FloatingWhatsAppButton;
