import React from "react";

function ComingSoon() {
  const containerStyle = {
    textAlign: "center",
    marginTop: "10vh",
    transform: "translateY(-50%)",
  };

  const headingStyle = {
    fontSize: "2em",
  };

  const paragraphStyle = {
    fontSize: "1.2em",
    color: "#888",
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Coming Soon...</h1>
      <p style={paragraphStyle}>
        This section will be created and launched soon!
      </p>
    </div>
  );
}

export default ComingSoon;
